import React, { useContext } from "react";
import ContentContext from "../../../contexts/ContentContext";
import HeroHome from "./HeroHome";
import HeroColor from "./HeroColor";
import HeroBackgroundImage from "./HeroBackgroundImage";

const COLOR = "color";
const HOMEPAGE = "homepage";
const INSIGHT = "Post";
const JOB = "Job";
const CASE_STUDY = "Case_study";
const IMAGE = "image";

const Hero = ({ data }) => {
  const content = useContext(ContentContext);

  const renderHero = () => {
    switch (content.nodeType) {
      case INSIGHT:
        return data.featuredImageMobile ||
          data.featuredImageDesktop ||
          data.featuredImageTablet ||
          data.featuredImage ? (
          <HeroBackgroundImage pageName={`Article`} data={data} />
        ) : (
          <HeroColor pageName={`Article`} data={data} />
        );
      case JOB:
        return data.featuredImageMobile ||
          data.featuredImageDesktop ||
          data.featuredImageTablet ||
          data.featuredImage ? (
          <HeroBackgroundImage pageName={`Jobs`} data={data} />
        ) : (
          <HeroColor pageName={`Jobs`} data={data} />
        );
      case CASE_STUDY:
        return data.featuredImageMobile ||
          data.featuredImageDesktop ||
          data.featuredImageTablet ||
          data.featuredImage ? (
          <HeroBackgroundImage pageName={`Case Study`} data={data} />
        ) : (
          <HeroColor pageName={`Case Study`} data={data} />
        );
      default:
        switch (content.HeroSection?.heroType) {
          case HOMEPAGE:
            return <HeroHome data={data} />;
          case COLOR:
            return <HeroColor data={data} />;
          case IMAGE:
            return <HeroBackgroundImage data={data} />;
          default:
            return <HeroColor data={data} />;
        }
    }
  };
  return renderHero();
};

export default Hero;
