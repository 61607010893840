import React, { useContext } from "react";
import styled from "@emotion/styled";
import { css, useTheme } from "@emotion/react";
import Logo from "../../icons/BrandishLogoLarge.svg";
import ContentContext from "../../../contexts/ContentContext";
// import BackgroundImage from "gatsby-background-image";
import { BgImage as BackgroundImage } from "gbimage-bridge";
import BrandishLogo from "../../icons/brandish-header-logo.svg";
import PageLink from "../PageLink";
import Image from "../image";

const CASE_STUDY = "Case_study";
const JOB = "Job";
const PAGE = "Page";

const HeroComponent = styled(BackgroundImage)`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => (props.contentType === JOB ? "345px" : "auto")};
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    height: ${props => (props.contentType === JOB ? 430 : 600)}px;
  }
`;

const HeroBar = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.regular};
  color: #f4edeb;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 2rem 2rem 3rem;
    margin-bottom: ${props => (props.contentType === JOB ? 0 : 5)}rem;
  }

  .brandish-logo {
    width: 130px;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
    }
  }

  h2 {
    font-size: 3.5rem;
    margin: 0;
    font-family: ${props => props.theme.fonts.regular};

    a {
      text-decoration: none;
      color: #f4edeb;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 4.5rem;
    }
  }
`;

const PageName = styled.p`
  font-size: 2.1rem;
  margin: 0 ${props => (props.isNavIconHidden ? 0 : "6rem")} 0 0;
`;

const HeroText = styled.div`
  padding: 1rem 0 0 1rem;
  width: 100%;
  margin: 0 auto;
  color: #f4edeb;
  display: ${props => (props.contentType === JOB ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    padding-left: 6rem;
  }
`;

const HeroTextHeading = styled.h1`
  display: block;
  font-size: 4.8rem;
  font-weight: 500;
  width: 100%;
  line-height: 60px;
  font-family: ${props => props.theme.fonts.medium};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10.2rem;
    line-height: 120px;
  }
`;

const HeroTextBottom = styled.div`
  display: ${props => (props.contentType === JOB ? "none" : "flex")};
  align-items: flex-end;
  overflow-x: hidden;
  padding: 0;
  min-height: ${props => (props.contentType === PAGE ? "150px" : "350px")};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    min-height: 250px;
  }
`;

const HeroTextBottomParagraph = styled.p`
  visibility: ${props => (props.contentType === JOB ? "hidden" : "visible")};
  width: 70%;
  font-size: 1.8rem;
  margin-bottom: 3rem;
  line-height: 30px;
  font-family: ${props => props.theme.fonts.regular};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    font-size: 2.5rem;
    line-height: 40px;
    margin-bottom: 5rem;
  }
`;

const BrandishHeroLogo = styled(Logo)`
  width: 175px;
  height: 175px;
  margin-right: -35px;
  /* margin-bottom: -5px; */
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    margin-right: 0;
    /* margin-bottom: -15px; */
    width: 255px;
    height: 255px;
  }
`;

const determineLogoAlignment = value => {
  switch (value) {
    case "left":
      return "25%";
    case "center":
      return "50%";
    case "right":
      return "70%";
    default:
      return "50%";
  }
};

const HeroLogo = styled(Image)`
  max-width: 300px;
  width: 100%;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 300px;
    top: 50%;
    left: ${props => determineLogoAlignment(props.logoalignment)};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 400px;
    transform: translate(-50%, -50%);
  }
  &.gatsby-image-wrapper {
    position: absolute !important;
    img {
      height: auto !important;
    }
  }
`;

const HeroBackgroundImage = ({ pageName, data }) => {
  const content = useContext(ContentContext);
  const contentType = content?.nodeType;
  // console.log(data);
  const theme = useTheme();
  // check if there are responsive featured images passed in, if not, pull from the default wordpress featured image
  const sources =
    data.featuredImageMobile ||
    data.featuredImageTablet ||
    data.featuredImageDesktop
      ? [
          data.featuredImageMobile?.localFile?.childImageSharp?.gatsbyImageData,
          {
            ...data.featuredImageTablet?.localFile?.childImageSharp
              ?.gatsbyImageData,
            media: `(min-width: ${theme.breakpoints.sm})`,
          },
          {
            ...data.featuredImageDesktop?.localFile?.childImageSharp
              ?.gatsbyImageData,
            media: `(min-width: ${theme.breakpoints.lg})`,
          },
        ]
      : data.featuredImage?.localFile?.childImageSharp?.gatsbyImageData ||
        content.HeroSection?.backgroundImage?.localFile?.childImageSharp
          ?.gatsbyImageData;
  return (
    <HeroComponent contentType={contentType} image={sources}>
      <HeroBar contentType={contentType}>
        <PageLink to="/">
          <BrandishLogo className="brandish-logo" />
        </PageLink>
        <PageName isNavIconHidden={content?.PageCptFields?.hideNavIcon}>
          {pageName || data.pageName || content.title}
        </PageName>
      </HeroBar>
      <HeroText contentType={contentType}>
        {contentType !== JOB && contentType !== CASE_STUDY && (
          <HeroTextHeading contentType={contentType}>
            {data.title}
          </HeroTextHeading>
        )}
        <HeroTextBottom contentType={contentType}>
          <HeroTextBottomParagraph contentType={contentType}>
            {data.subtitle}
          </HeroTextBottomParagraph>
          {contentType === CASE_STUDY ? null : (
            <BrandishHeroLogo
              css={css`
                & path {
                  fill: ${data.logoColor || theme.colors.lightPeach};
                }
              `}
              preserveAspectRatio="xMidYMax meet"
            />
          )}
        </HeroTextBottom>
      </HeroText>
      {contentType === CASE_STUDY && data?.CaseStudy.hero?.logo && (
        <HeroLogo
          logoalignment={data?.logoAlignment}
          image={data?.CaseStudy.hero?.logo}
        />
      )}
    </HeroComponent>
  );
};

export default HeroBackgroundImage;
