import React, { useContext } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ContentContext from "../../../contexts/ContentContext";
import Typed from "react-typed";
import BrandishLogo from "../../icons/brandish-logo.svg";

const HeroComponent = styled.div`
  padding: 0;
  padding-bottom: 6rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-bottom: 15rem;
  }
  /* background-color: ; */
`;

const HeroBar = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: ${props => props.theme.fonts.regular};
  color: #f4edeb;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 2rem;
    margin-bottom: 6rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0rem 2rem;
  }

  h2 {
    font-size: 3.5rem;
    margin: 0;
    font-family: ${props => props.theme.fonts.regular};

    a {
      text-decoration: none;
      color: #f4edeb;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 4.5rem;
    }
  }

  p {
    font-size: 2.1rem;
    margin: 0rem 6rem 0 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 2rem 6rem 0 0;
    }
  }
`;

const HeroText = styled.div`
  padding: 1rem 0 0 1.5rem;
  width: 100%;
  margin: 0 auto;
  color: #f4edeb;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: 6rem;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: 500;
    width: 100%;
    line-height: 60px;
    font-family: ${props => props.theme.fonts.medium};
    position: relative;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 10.2rem;
      line-height: 120px;
    }
  }

  p {
    width: 70%;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    line-height: 30px;
    font-family: ${props => props.theme.fonts.regular};

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
      font-size: 2.5rem;
      line-height: 40px;
      margin-bottom: 5rem;
    }
  }

  .title-logo {
    width: 50%;
    margin-bottom: 4rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 25%;
      min-width: 350px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      margin-bottom: 6rem;
    }
  }

  .logo-container {
    display: flex;
    align-items: flex-end;
    overflow-x: hidden;
    padding: 0;
    min-height: 200px;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      min-height: 250px;
    }
  }

  .typed-component {
    position: relative;
  }

  .mobile-break {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: none;
    }
  }

  .default-component {
    /* content: "creative"; */
    position: absolute;
    left: 0;
    color: #f5f5f5;
    opacity: 0.2;
  }
`;

// const BrandishHeroLogo = styled(Logo)`
//   width: 175px;
//   height: 175px;
//   margin-right: -35px;
//   /* margin-bottom: -5px; */

//   @media (min-width: ${props => props.theme.breakpoints.sm}) {
//     margin-right: 0;
//     /* margin-bottom: -15px; */
//     width: 255px;
//     height: 255px;
//   }
// `;
const HeroHome = ({ data }) => {
  const content = useContext(ContentContext);
  return (
    <HeroComponent
      css={css`
        background-color: ${data.backgroundColor};
      `}
    >
      <HeroBar>
        <p>{content.title}</p>
      </HeroBar>
      <HeroText>
        <div className="title-logo">
          <BrandishLogo
            css={css`
              width: 100%;
              height: auto;
            `}
          />
        </div>
        <h1>
          We craft <span></span>
          <br className="mobile-break" />
          <Typed
            className="typed-component"
            strings={[
              "creative",
              "powerful",
              "adaptable",
              "profitable",
              "remarkable",
            ]}
            typeSpeed={150}
            showCursor={false}
            loop
          ></Typed>{" "}
          <br />
          businesses.
        </h1>
      </HeroText>
    </HeroComponent>
  );
};

export default HeroHome;
