import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { css, useTheme } from "@emotion/react";
import Logo from "../../icons/BrandishLogoLarge.svg";
import ContentContext from "../../../contexts/ContentContext";
import Typed from "react-typed";
import BrandishLogo from "../../icons/brandish-header-logo.svg";
import PageLink from "../PageLink";
import pixelWidth from "string-pixel-width";
import { useWindowWidth } from "../../shared/hooks";
const PADDING_SMALL = 15;
const PADDING_LARGE = 60;

const HeroComponent = styled.div`
  padding: 0;
  min-height: 500px;
  /* background-color: ; */
`;

const HeroBar = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.regular};
  color: #f4edeb;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem;
    margin-bottom: 12rem;
  }

  h2 {
    font-size: 3.5rem;
    margin: 0;
    font-family: ${props => props.theme.fonts.regular};

    a {
      text-decoration: none;
      color: #f4edeb;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 4.5rem;
    }
  }
`;

const PageName = styled.p`
  font-size: 2.1rem;
  margin: 0 ${props => (props.isNavIconHidden ? 0 : "6rem")} 0 0;
`;

const LogoLink = styled(PageLink)`
  display: block;
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: 4.5rem;
  }
  .brandish-logo {
    width: 130px;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      width: auto;
    }
  }
`;

const HeroText = styled.div`
  padding: 1rem 0 0 ${PADDING_SMALL}px;
  width: 100%;
  margin: 0 auto;
  color: #f4edeb;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: ${PADDING_LARGE}px;
  }

  h1 {
    position: relative;
    font-size: 4.8rem;
    font-weight: 500;
    width: 100%;
    height: auto;
    margin-top: 4rem;
    min-height: 18rem;
    line-height: 60px;
    font-family: ${props => props.theme.fonts.medium};

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      min-height: 36rem;
      font-size: 10.2rem;
      line-height: 120px;
      margin-bottom: 4rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0;
      min-height: 24rem;
    }

    br {
      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        display: none;
      }
    }
  }

  p {
    width: 70%;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    line-height: 30px;
    font-family: ${props => props.theme.fonts.regular};

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
      font-size: 2.5rem;
      line-height: 40px;
      margin-bottom: 5rem;
    }
  }

  div {
    display: flex;
    align-items: flex-end;
    overflow-x: hidden;
    padding: 0;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      min-height: 250px;
    }
  }

  .default-title {
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.offWhite};
    opacity: 0.3;
  }

  .hero-title {
    position: absolute;
    left: 0;
  }
`;

const BrandishHeroLogo = styled(Logo)`
  width: 175px;
  height: 175px;
  margin-right: -35px;
  /* margin-bottom: -5px; */

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 0;
    /* margin-bottom: -15px; */
    width: 255px;
    height: 255px;
  }
`;
const HeroColor = ({ data, pageName }) => {
  const content = useContext(ContentContext);
  const theme = useTheme();
  const width = useWindowWidth();
  // create a ref for the Typed component to use to be able to call methods on the Typed instance
  const typedRef = useRef(null);
  const [fullText, setFullText] = useState(null);

  const calculateBreakTags = useCallback(() => {
    let words = data.title?.replace(/<br \/>|<br\/>/g, "");
    words = words?.split(" ");
    // get pixel size of text
    const pixelSize = width < 768 ? 48 : 102;
    // get size of the window
    let windowSize;
    if (width < 768) {
      windowSize = width - PADDING_SMALL * 2;
    } else if (width < 2000) {
      windowSize = width - PADDING_LARGE * 2;
    } else {
      windowSize = width - PADDING_LARGE * 2;
    }
    let text = "";
    let currentLine = "";
    words &&
      words.forEach(word => {
        // get width of current line
        let currentLength = pixelWidth(currentLine, { size: pixelSize });
        // get width of current word
        let currentWord = pixelWidth(word, { size: pixelSize });
        // add link break if sum of line and word are greater than window size
        // console.log(currentLength + currentWord, windowSize);
        if (currentLength + currentWord < windowSize) {
          text += word + " ";
          currentLine += word + " ";
        } else {
          text += "<br />";
          text += word + " ";
          currentLine = "";
          currentLine += word + " ";
        }
      });
    setFullText(text);
  }, [width, data]);
  useEffect(() => {
    calculateBreakTags();
  }, [width, calculateBreakTags]);
  useEffect(() => {
    // reset the typed animation when window width changes or the ref itself changes
    typedRef.current && typedRef.current.reset();
  }, [typedRef, width]);
  return (
    <HeroComponent
      css={css`
        background-color: ${data.backgroundColor || theme.colors.lightTeal};
      `}
    >
      <HeroBar>
        <LogoLink to="/">
          <BrandishLogo className="brandish-logo" />
        </LogoLink>
        <PageName isNavIconHidden={content?.PageCptFields?.hideNavIcon}>
          {pageName || data.pageName || content.title}
        </PageName>
      </HeroBar>
      <HeroText>
        <h1>
          <span
            className="default-title"
            dangerouslySetInnerHTML={{ __html: fullText }}
          />
          {data.title && (
            <Typed
              className="hero-title"
              strings={[`${fullText}`]}
              typeSpeed={120}
              showCursor={false}
              // set the value of typedRef to be the instance of Typed so we can call its methods like reset, start, stop etc.
              typedRef={typed => {
                typedRef.current = typed;
              }}
              loop
            />
          )}
        </h1>
        {/* <h1>{data.title}</h1> */}
        <div className="hero-subtitle--box">
          <p>{data.subtitle}</p>
          <BrandishHeroLogo
            css={css`
              & path {
                fill: ${data.logoColor || theme.colors.lightPeach};
              }
            `}
            preserveAspectRatio="xMidYMax meet"
          />
        </div>
      </HeroText>
    </HeroComponent>
  );
};

export default HeroColor;
